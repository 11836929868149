.MuiTableHead-root {
    background: -webkit-gradient(linear, left top, left bottom, from(#0075a9), to(#003049));
    color: white;
    border-spacing: 0;
    border: 1px solid #ededed;
}

.MuiTableRow-root.MuiTableRow-head {
    border-spacing: 0;
    border: 1px solid #ededed;
}

.MuiTableRow-root.MuiTableRow-head th {
    text-align: center;
    border: 1px solid #ededed;
    color: white;
    font-size: 14px;
    font-weight: 700; 
}


.row-active {
    background-color: #D9E7F4 !important;
    /* position: sticky;
    top: 19%;
    z-index: 10; */   
}

.not-row-active {
    background-color: white !important
}

.advanced-filter-btn {
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
}

.advanced-filter-btn:disabled,
.advanced-filter-btn[disabled] {
    border: 1px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
    background: fixed;
}

.text-dark-orange {
    color: #ff8c00;
    font-weight: 600;
}