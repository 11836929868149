.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card-header {
  background: rgb(0, 113, 145);
  background: linear-gradient(180deg, rgba(0, 113, 145, 1) 0%, rgba(0, 129, 150, 1) 72%);
  color: white;
  font-weight: 300;
}

.nav-pills.verticalNav .nav-item>.nav-link {
  max-height: 45px;
}

.nav-pills.verticalNav .nav-item>.nav-link.active {
  background-color: red;
  max-height: 45px;
  color: white;
}

.nav.verticalNav .nav-link.active {
  background-color: #003049 !important;
  max-height: 45px;
  color: white;
}

.nav.verticalNav .nav-link {
  max-height: 45px;
}

.nav.nav-tabs.nav-fill .nav-item.nav-link.active {
  color: #003049 !important;
  ;
  font-weight: 600;
  border-bottom: 5px solid #003049 !important;
}

.text-teal {
  color: #006400;
  font-weight: 600;
}

.template_circle,
.alert_status {
  border: 1px solid white;
}

.round {
  /* status */

  position: absolute;
  width: 16px;
  height: 16px;
  right: 108px;
  top: 68px;

  /* Rectangle 97 */

  box-sizing: border-box;

  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  /* green */
  background: #26bd27;
  /* grey2 */
  border: 0.5px solid #a1a1a1;
  border-radius: 100px;

  /* Vector 12 */

  position: absolute;
  left: 16.35%;
  right: 21.16%;
  top: 31.25%;
  bottom: 31.25%;

  border: 2px solid #ffffff;
}

.circle-grey {
  width: 9px;
  height: 9px;
  border-radius: 100px;

  color: white;
  line-height: 10px !important;
  text-align: center;
  background-color: rgba(161, 161, 161, 1);
  margin-left: 5px;
}

.circle-yellow {
  width: 9px;
  height: 9px;
  border-radius: 100px;

  font-size: 12px;
  color: white;
  line-height: 10px !important;
  text-align: center;
  background-color: rgb(248, 248, 37);
  margin-left: 5px;
}

.circle-green {
  width: 9px;
  height: 9px;
  border-radius: 100px;

  font-size: 12px;
  color: white;
  line-height: 10px !important;
  text-align: center;
  background-color: green;
  margin-left: 5px;
}

.circle-red {
  width: 9px;
  height: 9px;
  border-radius: 100px;

  font-size: 12px;
  color: white;
  line-height: 10px !important;
  text-align: center;
  background-color: red;
  margin-left: 5px;
}

.circle-black {
  width: 9px;
  height: 9px;
  border-radius: 100px;

  font-size: 12px;
  color: white;
  line-height: 10px !important;
  text-align: center;
  background-color: black;
  margin-left: 5px;
}

.circle-white {
  width: 9px;
  height: 9px;
  border-radius: 100px;

  font-size: 12px;
  color: white;
  line-height: 10px !important;
  text-align: center;
  background-color: white;
  margin-left: 5px;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:hover {
  background: -webkit-gradient(linear, left top, right bottom, from(#189AB4), to(#006096));
  border-color: #003049;
  border-radius: 4px;
}

#barcode-table-total-reads-button {
  background: -webkit-gradient(linear, left top, right bottom, from(#53cbe3), to(#3fb9ff));
  border-color: #003049;
  border-radius: 4px;
  font-weight: 700;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  &:hover,
  &:focus {
    background: -webkit-gradient(linear, left top, right bottom, from(#105d72), to(#002a38));
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
}

#barcode-table-total-reads-button:active {
  border-color: #003049;
  border-radius: 4px;
  font-weight: 700;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  background:  -webkit-gradient(linear, left top, right bottom, from(#189AB4), to(#006096));
  box-shadow: none;
}

.barcode-table-total-reads-message {
  font-size: 1.125rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}

.sticky-10 {
  position: sticky;
  top: 5%;
  z-index: 10;
}

.card-header {
  height: 50px;
}

.card-header strong {
  vertical-align: middle;
}

.tableContainer {
  margin: 0px auto;
  position: relative;
  /*  width: 500px; */
}

#header {
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
}

.sticky-10-fixed {
  position: fixed !important;

}

.js-plotly-plot .plotly .modebar {
  left: 70%;
  transform: translateY(-45%);
  margin-bottom: 20px;
}

.nav-tabs a.nav-item {
  font-family: Arial, Helvetica, sans-serif;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.bg-white-non-filtered {
  background-color: white;
  border-bottom: 1pt solid #a1a1a1 !important;
  border-radius: 5px;
}

.bg-white {
  background-color: white;
}

@media screen and (max-width: 590px) {
  #headerButtons {
    margin-Left: 5px;
  }

  #mainContainer,
  #stickeHeaderContainer {
    width: fit-content !important;
    background-color: #FAFAFA;
  }

  #horizontalSpinner{
    width: 590px !important;
  }

  .advanced-filter-btn {
    font-size: 11px !important;
    height: 60px;
  }

  #DoneIcon {
    font-size: small;
  }

  #downloadAmpRunButton {
    margin-left: 12px;
  }
}

@media screen and (min-width: 600px) {
  #headerButtons {
    max-Width: 99%;
    margin-Left: 15px;
  }

  #mainContainer,
  #stickeHeaderContainer {
    max-width: 100px;
    background-color: #FAFAFA;
  }

  .advanced-filter-btn {
    font-size: 9px !important;
    width: 10px;
    height: 10px;
    vertical-align: middle;
  }

  .advanced-filter-btn-pager {
    font-size: 15px !important;
    vertical-align: middle;
    width: 10px;
    height: 10px !important;
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
  }

  .filter-Child {
    width: 10px !important;
    height: 10px !important;
  }
}

@media screen and (min-width: 768px) {
  #headerButtons {
    max-Width: 99%;
    margin-Left: 15px;
  }

  #mainContainer,
  #stickeHeaderContainer {
    max-width: 738px;
    background-color: #FAFAFA;
  }

  #stickeHeaderContainer {
    width: 100% !important;
    margin: 0px;
  }
}

@media screen and (min-width: 890px) and (max-width: 1190px) {

  #headerButtons {
    max-Width: 99%;
    margin-Left: 15px;
    height: 55px;
  }

  .MuiTableCell-root {
    padding: 0px !important;
    font-size: 9px !important;
  }

  #mainContainer,
  #stickeHeaderContainer {
    max-width: 100%;
    background-color: #FAFAFA;
    margin: 0px;
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  #stickeHeaderContainer {
    max-width: 98% !important;
    padding: 0px !important;
    margin-left: 25px !important;
  }

  .buttonsIcon {
    width: 10px;
    height: 10px;
    float: right;
    vertical-align: middle;
    /*  margin-left: 0 !important;
    padding-left: 0 !important; */
  }

  .buttonsIconChild {
    width: 10px !important;
    height: 10px !important;
  }

  .advanced-filter-btn {
    font-size: 8px !important;
    height: 70% !important;
    padding: 4px !important;
  }

  .advanced-filter-btn-pager {
    font-size: 8px !important;
    height: 70% !important;
    padding: 4px !important;
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
  }

  .filter-Child {
    width: 25px !important;
    height: 25px !important;
  }

  .MuiOutlinedInput-root {
    height: 40px !important;
  }

  #completeTableContainer {
    overflow-x: scroll;
  }

  .sequence-btn {
    float: right;
    margin-right: 30px !important;
    margin-bottom: 15px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1290px) {
  #headerButtons {
    max-Width: 99%;
    margin-Left: 15px;
    height: 55px;
  }

  #downloadAmpRunButton {
    margin-left: 10px;
  }

  .MuiTableCell-root {
    padding: 0px !important;
    font-size: 10px !important;
  }

  #mainContainer,
  #stickeHeaderContainer {
    max-width: 100%;
    background-color: #FAFAFA;
    margin: 0px;
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  #stickeHeaderContainer {
    max-width: 98% !important;
    padding: 0px !important;
    margin-left: 25px !important;
  }

  .buttonsIcon {
    width: 10px;
    height: 10px;
    float: right;
    vertical-align: middle;
    /*  margin-left: 0 !important;
    padding-left: 0 !important; */
  }

  .buttonsIconChild {
    width: 15px !important;
    height: 10px !important;
  }

  .advanced-filter-btn {
    font-size: 8px !important;
    height: 30px !important;
    padding: 4px !important;
    margin-top: 20px !important;
  }

  .advanced-filter-btn-pager {
    font-size: 8px !important;
    height: 30px !important;
    padding: 4px !important;
    margin-top: 20px !important;
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
  }

  #header-advanced-filter-1 {
    margin-top: 19px !important;
  }

  #header-advanced-filter-2 {
    margin-top: 19px !important;
  }

  #header-advanced-filter-3 {
    margin-top: 19px !important;
  }

  .filter-Child {
    width: 25px !important;
    height: 25px !important;
  }

  .MuiOutlinedInput-root {
    height: 30px !important;
    margin-top: 14px !important;
  }

  #completeTableContainer {
    overflow-x: scroll;
  }

  .sequence-btn {
    float: right;
    margin-right: 30px !important;
    margin-bottom: 20px !important;
  }

  #dataMap {
    display: inline-block;
    word-wrap: break-word;
    width: 100px
  }

  #headerSpan {
    width: 100px;
    word-wrap: break-word;
    display: inline-block;
  }

  #filteredEditIcon {
    font-size: 15px;
  }
}


@media screen and (min-width: 1300px) and (max-width: 1490px) {
  #stickyBarcodeHeader {
    max-width: 94% !important;  
    top: 9.5rem !important; 
  }

  #headerButtons {
    max-Width: 99%;
    margin-Left: 15px;
    height: 55px;
  }

  #downloadAmpRunButton {
    margin-left: 10px;
  }

  .MuiTableCell-root {
    padding: 0px !important;
    font-size: 10px !important;
  }

  #mainContainer,
  #stickeHeaderContainer {
    max-width: 100%;
    background-color: #FAFAFA;
    margin: 0px;
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  #stickeHeaderContainer {
    max-width: 98% !important;
    padding: 0px !important;
    margin-left: 25px !important;
  }

  .buttonsIcon {
    width: 15px;
    height: 15px;
    float: right;
    vertical-align: middle;
    /*  margin-left: 0 !important;
    padding-left: 0 !important; */
  }

  .buttonsIconChild {
    width: 15px !important;
    height: 15px !important;
  }

  .advanced-filter-btn {
    font-size: 8px !important;
    height: 30px !important;
    padding: 4px !important;
    margin-top: 20px !important;
  }

  .advanced-filter-btn-pager {
    font-size: 8px !important;
    height: 30px !important;
    padding: 4px !important;
    margin-top: 20px !important;
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
  }

  #header-advanced-filter-1 {
    margin-top: 19px !important;
  }

  #header-advanced-filter-2 {
    margin-top: 19px !important;
  }

  #header-advanced-filter-3 {
    margin-top: 19px !important;
  }

  .filter-Child {
    width: 25px !important;
    height: 25px !important;
  }

  .MuiOutlinedInput-root {
    height: 30px !important;
    margin-top: 15px !important;
  }

  #completeTableContainer {
    overflow-x: scroll;
  }

  .sequence-btn {
    float: right;
    margin-right: 30px !important;
    margin-bottom: 20px !important;
  }

  #dataMap {
    display: inline-block;
    word-wrap: break-word;
    width: 180px
  }

  #headerSpan {
    width: 180px;
    word-wrap: break-word;
    display: inline-block;
  }

  #filteredEditIcon {
    font-size: 15px;
  }

}


@media screen and (min-width: 1500px) and (max-width: 1599px) {
  #stickyBarcodeHeader {
    max-width: 95% !important; 
    top: 11rem !important; 
  }

  .disabledFilter {
    height: 28px !important;
  }

  #downloadAmpRunButton {
    margin-left: 10px;
  }

  #ViewIcon {
    float: right;
    width: 30px !important;
    height: 30px !important;
  }

  #headerButtons {
    max-Width: 98%;
    margin-Left: 15px;
    height: 55px;
  }

  #mainContainer,
  #stickeHeaderContainer {
    max-width: 100%;
    background-color: #FAFAFA;
    margin: 0px;
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  #dataMap {
    display: inline-block;
    word-wrap: break-word;
    width: 155px
  }

  #headerSpan {
    width: 155px;
    word-wrap: break-word;
    display: inline-block;
  }

  #filteredEditIcon {
    font-size: 13px !important;
  }

  .MuiTableCell-root {
    padding: 0px !important;
    font-size: 12px !important;
  }

  #stickeHeaderContainer {
    max-width: 97% !important;
    padding: 0px !important;
    margin-left: 28px !important;
  }

  .buttonsIcon {
    width: 13px;
    height: 13px;
    float: right;
    vertical-align: middle;
    /*  margin-left: 0 !important;
    padding-left: 0 !important; */
  }

  .buttonsIconChild {
    width: 13px !important;
    height: 13px !important;
  }

  #header-advanced-filter-1 {
    margin-top: 9px !important;
  }

  #header-advanced-filter-2 {
    margin-top: 9px !important;
  }

  #header-advanced-filter-3 {
    margin-top: 9px !important;
  }

  .advanced-filter-btn {
    font-size: 10px !important;
    height: 40px !important;
    margin-top: 12px !important;
  }

  .advanced-filter-btn-pager {
    font-size: 10px !important;
    height: 40px !important;
    margin-top: 12px !important;
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
  }

  .filter-Child {
    width: 25px !important;
    height: 25px !important;
  }

  .MuiOutlinedInput-root {
    height: 40px !important;
    margin-top: 5px !important;
  }


  #completeTableContainer {
    overflow-x: scroll;
  }

  #analysisStatusDiv {
    margin-left: 10px !important;
  }

  .sequence-btn {
    float: right;
    margin-right: 30px !important;
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1710px) {

  #stickyBarcodeHeader {
    max-width: 94% !important; 
    top: 9.5rem !important; 
  }

  .disabledFilter {
    height: 28px !important;
  }

  #downloadAmpRunButton {
    margin-left: 10px;
  }

  #headerButtons {
    max-Width: 98%;
    margin-Left: 15px;
    height: 55px;
  }

  #mainContainer {
    max-width: 100%;
    background-color: #FAFAFA;
    margin: 0px;
  }

  .advanced-filter-btn {
    font-size: 11px !important;
    height: 40px !important;
    margin-top: 13px !important;
  }

  .advanced-filter-btn-pager {
    font-size: 11px !important;
    height: 40px !important;
    margin-top: 13px !important;
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
  }

  #header-advanced-filter-1 {
    margin-top: 10px !important;
  }

  #header-advanced-filter-2 {
    margin-top: 10px !important;
  }

  #header-advanced-filter-3 {
    margin-top: 10px !important;
  }

  .filter-Child {
    width: 25px !important;
    height: 25px !important;
  }

  .MuiOutlinedInput-root {
    height: 40px !important;
    margin-top: 5px !important;
  }

  .MuiTableCell-root {
    padding: 0px !important;
    font-size: 11px !important;
  }

  #stickeHeaderContainer {
    max-width: 99% !important;
    margin-left: 10px !important;
  }

  .buttonsIcon {
    width: 13px;
    height: 13px;
    float: right;
    vertical-align: middle;
    /*  margin-left: 0 !important;
    padding-left: 0 !important; */
  }

  .buttonsIconChild {
    width: 13px !important;
    height: 13px !important;
  }

  #completeTableContainer {
    overflow-x: scroll;
  }

  .sequence-btn {
    float: right;
    margin-right: 30px !important;
    margin-bottom: 10px !important;
  }

  #dataMap {
    display: inline-block;
    word-wrap: break-word;
    width: 165px
  }

  #headerSpan {
    width: 165px;
    word-wrap: break-word;
    display: inline-block;
  }

  #filteredEditIcon {
    font-size: 13px;
  }

  #analysisStatusDiv {
    margin-left: 10px !important;
  }
}

@media screen and (min-width: 1711px) and (max-width: 1910px) {
  #stickyBarcodeHeader {
    max-width: 94.5% !important; 
    top: 11rem !important; 
  }

  .disabledFilter {
    height: 28px !important;
  }

  #downloadAmpRunButton {
    margin-left: 10px;
  }

  #headerButtons {
    max-Width: 98%;
    margin-Left: 15px;
    height: 55px;
  }

  #mainContainer {
    max-width: 100% !important;
    background-color: #FAFAFA;
    margin: 0px;
  }

  #stickeHeaderContainer {
    max-width: 99% !important;
    margin-left: 14px !important;
  }

  .MuiTableCell-root {
    padding: 0px !important;
    font-size: 13px !important;
  }

  .advanced-filter-btn {
    font-size: 11px !important;
    height: 40px !important;
    margin-top: 11px !important;
  }

  
  .advanced-filter-btn-pager {
    font-size: 11px !important;
    height: 40px !important;
    margin-top: 11px !important;
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
  }

  #header-advanced-filter-1 {
    margin-top: 9px !important;
  }

  #header-advanced-filter-2 {
    margin-top: 9px !important;
  }

  #header-advanced-filter-3 {
    margin-top: 9px !important;
  }

  .filter-Child {
    width: 25px !important;
    height: 25px !important;
  }

  .buttonsIcon {
    width: 15px;
    height: 15px;
    float: right;
    vertical-align: middle;
    /*  margin-left: 0 !important;
    padding-left: 0 !important; */
  }

  .buttonsIconChild {
    width: 15px !important;
    height: 15px !important;
  }

  #completeTableContainer {
    overflow-x: hidden;
  }

  .MuiOutlinedInput-root {
    height: 40px !important;
    margin-top: 5px !important;
  }

  .sequence-btn {
    float: right;
    margin-right: 30px !important;
    margin-bottom: 10px !important;
  }

  #dataMap {
    display: inline-block;
    word-wrap: break-word;
    width: 160px
  }

  #headerSpan {
    width: 160px;
    word-wrap: break-word;
    display: inline-block;
  }

  #filteredEditIcon {
    font-size: 15px;
  }

  #analysisStatusDiv {
    margin-left: 18px !important;
  }
}

@media screen and (min-width: 1911px) and (max-width: 2199px) {
  #stickyBarcodeHeader {
    max-width: 95.5% !important; 
    top: 11rem !important; 
  }

  .disabledFilter {
    height: 29px !important;
  }

  #downloadAmpRunButton {
    margin-left: 10px;
  }

  #headerButtons {
    max-Width: 98%;
    margin-Left: 15px;
    height: 55px;
    margin-bottom: 10px !important;
  }

  #mainContainer {
    max-width: 100%;
    background-color: #FAFAFA;
  }

  .MuiTableCell-root {
    padding: 0px !important;
    font-size: 13px !important;
  }

  .MuiOutlinedInput-root {
    height: 50px !important;
    margin-top: 5px !important;
  }

  .advanced-filter-btn {
    font-size: 11px !important;
    height: 50px !important;
    margin-top: 8px !important;
  }

  .advanced-filter-btn-pager {
    font-size: 11px !important;
    height: 40px !important;
    margin-top: 8px !important;
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
  }

  #header-advanced-filter-1 {
    margin-top: 9px !important;
  }

  #header-advanced-filter-2 {
    margin-top: 9px !important;
  }

  #header-advanced-filter-3 {
    margin-top: 9px !important;
  }

  #ViewIcon {
    margin-top: 6px !important;
  }

  .filter-Child {
    width: 25px !important;
    height: 25px !important;
  }


  #stickeHeaderContainer {
    max-width: 99% !important;
    margin: 0px !important;
    margin-left: 15px !important;
  }

  .buttonsIcon {
    width: 15px;
    height: 15px;
    float: right;
    vertical-align: middle;
    /*  margin-left: 0 !important;
    padding-left: 0 !important; */
  }

  .buttonsIconChild {
    width: 15px !important;
    height: 15px !important;
  }

  .textFieldEdit {
    width: 100%;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .circle-white,
  .circle-black,
  .circle-green,
  .circle-grey,
  .circle-red,
  .circle-yellow {
    width: 11px !important;
    height: 11px !important;
  }

  #completeTableContainer {
    overflow-x: hidden;
  }

  .sequence-btn {
    float: right;
    margin-right: 30px !important;
    margin-bottom: 15px !important;
  }

  #dataMap {
    display: inline-block;
    word-wrap: break-word;
    width: 170px
  }

  #headerSpan {
    width: 170px;
    word-wrap: break-word;
    display: inline-block;
  }

  #analysisStatusDiv {
    margin-left: 19px !important;
  }

  #filteredEditIcon {
    font-size: 15px;
  }
}

@media screen and (min-width: 2200px) and (max-width: 2400px) {
  #stickyBarcodeHeader {
    max-width: 95.8% !important; 
    top: 9.5rem !important;
  }

  .disabledFilter {
    height: 29px !important;
  }

  #downloadAmpRunButton {
    margin-left: 10px;
  }

  #ViewIcon {
    float: right;
    padding-right: 20px;
    margin-top: 5px;
  }

  #headerButtons {
    max-Width: 99%;
    margin-Left: 15px;
    height: 55px;
    margin-bottom: 10px !important;
  }

  #mainContainer {
    max-width: 100%;
    background-color: #FAFAFA;
  }

  #stickeHeaderContainer {
    max-width: 100% !important;
    margin: 0px !important;
    margin-left: 8px !important;
  }

  .advanced-filter-btn {
    font-size: 15px !important;
    vertical-align: middle;
    height: 50px !important;
  }

  .advanced-filter-btn-pager {
    font-size: 15px !important;
    vertical-align: middle;
    height: 40px !important;
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
  }

  #header-advanced-filter-1 {
    margin-top: 9px !important;
  }

  #header-advanced-filter-2 {
    margin-top: 9px !important;
  }

  #header-advanced-filter-3 {
    margin-top: 9px !important;
  }


  .filter-Child {
    width: 30px !important;
    height: 30px !important;
  }

  .MuiTableCell-root {
    padding: 0px !important;
    font-size: 12px !important;
  }

  .buttonsIcon {
    width: 20px;
    height: 20px;
    float: right;
    vertical-align: middle;
    /*  margin-left: 0 !important;
    padding-left: 0 !important; */
  }

  .buttonsIconChild {
    width: 20px !important;
    height: 20px !important;
  }

  .textFieldEdit {
    width: 100%;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .circle-white,
  .circle-black,
  .circle-green,
  .circle-grey,
  .circle-red,
  .circle-yellow {
    width: 12px !important;
    height: 12px !important;
  }

  #completeTableContainer {
    overflow-x: hidden;
  }

  #dataMap {
    display: inline-block;
    word-wrap: break-word;
    width: 200px
  }

  #headerSpan {
    width: 200px;
    word-wrap: break-word;
    display: inline-block;
  }

  #filteredEditIcon {
    font-size: 20px;
  }

}

@media screen and (min-width: 2410px) and (max-width: 2700px) {
  #stickyBarcodeHeader {
    max-width: 96% !important; 
    top: 14rem !important;
  }

  .disabledFilter {
    height: 29px !important;
  }

  #downloadAmpRunButton {
    margin-left: 10px;
  }

  #ViewIcon {
    float: right;
    padding-right: 20px;
    margin-top: 5px;
  }

  #headerButtons {
    max-Width: 99%;
    margin-Left: 15px;
    height: 55px;
    margin-bottom: 10px !important;
  }

  #mainContainer {
    max-width: 100%;
    background-color: #FAFAFA;
  }

  #stickeHeaderContainer {
    max-width: 100% !important;
    margin: 0px !important;
    margin-left: 10px !important;
  }

  .advanced-filter-btn {
    font-size: 15px !important;
    vertical-align: middle;
    height: 50px !important;
  }

  .advanced-filter-btn-pager {
    font-size: 15px !important;
    vertical-align: middle;
    height: 40px !important;
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
  }

  #header-advanced-filter-1 {
    margin-top: 9px !important;
  }

  #header-advanced-filter-2 {
    margin-top: 9px !important;
  }

  #header-advanced-filter-3 {
    margin-top: 9px !important;
  }


  .filter-Child {
    width: 30px !important;
    height: 30px !important;
  }

  .MuiTableCell-root {
    padding: 8px !important;
    font-size: 13px !important;
  }

  .buttonsIcon {
    width: 20px;
    height: 20px;
    float: right;
    vertical-align: middle;
    /*  margin-left: 0 !important;
    padding-left: 0 !important; */
  }

  .buttonsIconChild {
    width: 20px !important;
    height: 20px !important;
  }

  .textFieldEdit {
    width: 100%;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .circle-white,
  .circle-black,
  .circle-green,
  .circle-grey,
  .circle-red,
  .circle-yellow {
    width: 12px !important;
    height: 12px !important;
  }

  #completeTableContainer {
    overflow-x: hidden;
  }

  #dataMap {
    display: inline-block;
    word-wrap: break-word;
    width: 180px
  }

  #headerSpan {
    width: 180px;
    word-wrap: break-word;
    display: inline-block;
  }

  #filteredEditIcon {
    font-size: 20px;
  }

}

@media screen and (min-width: 2800px) {
  #stickyBarcodeHeader {
    max-width: 97.5% !important; 
    top: 12.7rem !important;
  }
  
  .disabledFilter {
    height: 29px !important;
  }

  #downloadAmpRunButton {
    margin-left: 10px;
  }

  #ViewIcon {
    float: right;
    padding-right: 20px;
    margin-top: 5px;
  }

  #mainContainer {
    max-width: 100%;
    background-color: #FAFAFA;
  }

  #stickeHeaderContainer {
    max-width: 100% !important;
    margin: 0px !important;
  }

  #stickyHeaderTableHead {
    width: 100% !important;
    max-width: 100% !important;
  }

  #analysisStatusDiv {
    margin-left: 17px !important;
  }

  .advanced-filter-btn {
    font-size: 15px !important;
    vertical-align: middle;
    height: 60px !important;
  }

  .advanced-filter-btn-pager {
    font-size: 15px !important;
    vertical-align: middle;
    height: 40px !important;
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
  }  

  #header-advanced-filter-1 {
    margin-top: 1px !important;
  }

  #header-advanced-filter-2 {
    margin-top: 1px !important;
  }

  #header-advanced-filter-3 {
    margin-top: 1px !important;
  }

  .filter-Child {
    width: 30px !important;
    height: 30px !important;
  }

  .circle-white,
  .circle-black,
  .circle-green,
  .circle-grey,
  .circle-red,
  .circle-yellow {
    width: 13px !important;
    height: 13px !important;
  }

  .buttonsIcon {
    width: 20px;
    height: 20px;
    float: right;
    vertical-align: middle;
    /*  margin-left: 0 !important;
    padding-left: 0 !important; */
  }

  .buttonsIconChild {
    width: 20px !important;
    height: 20px !important;
  }

  .textFieldEdit {
    width: 100%;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
  }

  #headerButtons {
    max-Width: 99%;
    margin-Left: 15px;
    height: 55px;
    margin-bottom: 10px !important;
  }

  #completeTableContainer {
    overflow-x: hidden;
  }

  #dataMap {
    display: inline-block;
    word-wrap: break-word;
    width: 350px
  }

  #headerSpan {
    width: 350px;
    word-wrap: break-word;
    display: inline-block;
  }

  .MuiTableCell-root {
    padding: 5px !important;
    font-size: 15px !important;
  }

  /* #analysisStatusDiv {
    margin-left: 30px !important;
  } */

  #filteredEditIcon {
    font-size: 25px;
  }
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.advanced-filter-btn-pager:disabled,
.advanced-filter-btn-pager[disabled] {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  background: fixed;
}

.qtable_header {
  background-color: #dee2e6;
}

.qtable_header:hover {
  background-color: #0075a9;
}

.regular-table th:first-child,
.regular-table td:first-child,
#stickyBarcodeHeader th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    box-shadow: 2px 0 5px -2px rgba(0,0,0,0.1);
}

/* Ensure the header cells have a higher z-index */
.regular-table th:first-child,
#stickyBarcodeHeader th:first-child {
    z-index: 3;
}

.regular-table .ppmseq-row td {
  background-color: #A9A9A9 !important;
}