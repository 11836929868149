.nxs-modal .modal-content {
    width: 1240px;
    height: 970px !important;
    border-radius: 10px;
}

.nxs-modal .modal-content .modal-body .tab-content {
    padding-top: 18px;
    overflow: unset;
}

.nxs-modal .modal-content .modal-body .tab-content table {
    width: 98%;
}

.nxs-modal .modal-content .modal-body .tab-content table tr {
    height: 60px;
}

.search-tabs a.nav-link {
    font-size: 14px;
    line-height: 16px;
    height: 40px;
}

.search-tabs.nav-tabs .nav-item {
    padding-left: 35px;
    padding-right: 35px;
}

.search-tabs.nav-tabs[data-rb-event-key="runinfo"],
.search-tabs.nav-tabs[data-rb-event-key="library"] {
    padding-left: 22px;
    padding-right: 22px;
}

.status-group {
    width: 136px;
    height: 170px;
    padding-right: 8px;
    color: #A1A1A1;
    ;
    font-size: 12px;
}

.status-group .status-items {
    background: #FAFAFA;
    height: 170px;
}

.status-group .status-items .check-status {
    font-size: 14px;
    padding-left: 10px;
}

.status-group .status-items .check-status span {
    color: #555555;
}

.modal-footer .nxs-button.mr-auto {
    padding-left: 68px;
}

.modal-footer .nxs-button.last {
    padding-right: 68px;
}

.modal-footer .nxs-button button {
    min-width: 120px;
    width: 120px;
    background: -webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049));
}

.custom-date-picker input {
    height: 10px !important;
    font-size: 14px;
    line-height: 16px;
}

.custom-date-picker .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    line-height: 13px;
    font-size: 14px;
}


td {
    color: #3F3F3F;
    font-size: 14px;
    line-height: 16px;
}

td .btn {
    color: #3F3F3F;
    background: #FFFFFF;
    font-size: 14px;
    line-height: 16px;
    width: 150px;
    border-radius: 4px;
}

td .form-control[type="number"] {
    color: #3F3F3F;
    font-size: 14px;
    line-height: 16px;
}

td .form-control[type="text"] {
    color: #3F3F3F;
    font-size: 14px;
    line-height: 16px;
    width: 500px;
}

td input[type="date"].form-control,
td input[type="datetime-local"].form-control {
    width: 150px;
}


td .btn-primary:focus,
.btn-primary:hover {
    background: -webkit-gradient(linear, left top, right bottom, from(#E9F5F5), to(#D9E7F4));
    color: #008196;
    border-radius: 4px;
}

td .dropdown .btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background: -webkit-gradient(linear, left top, right bottom, from(#E9F5F5), to(#D9E7F4));
    color: #008196;
}

td .dropdown .dropdown-menu .dropdown-item {
    color: #3F3F3F;
    font-size: 14px;
    line-height: 16px;
}


input[type="number"] {
    height: 30px;
    padding-left: 4px;
    padding-right: 4px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

@media (min-width: 576px) {
    .modal-dialog.nxs-modal {
        max-width: 1140px;
    }
}

a#custom-filters-nav-tab-runinfo.nav-item.nav-link,
a#custom-filters-nav-tab-runstats.nav-item.nav-link,
#custom-filters-nav-tab-signals.nav-item.nav-link,
#custom-filters-nav-tab-template.nav-item.nav-link,
#custom-filters-nav-tab-library.nav-item.nav-link,
#custom-filters-nav-tab-analysis.nav-item.nav-link,
#custom-filters-nav-tab-phasing.nav-item.nav-link,
#custom-filters-nav-tab-accuracy.nav-item.nav-link {
    line-height: 25px !important;
}

#custom-filters-nav-tab-runinfo,
#custom-filters-nav-tab-runstats,
#custom-filters-nav-tab-signals,
#custom-filters-nav-tab-template,
#custom-filters-nav-tab-library,
#custom-filters-nav-tab-analysis,
#custom-filters-nav-tab-phasing,
#custom-filters-nav-tab-accuracy {
    height: 50px;
}