
.custom-scrollbar {
    max-height: 600px;
    overflow-y: auto;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 0.7em;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.1);
    outline: 1px solid slategrey;
    border-radius: 20px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
    background-color: rgb(233, 230, 230);
  }