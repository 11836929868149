body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div .navbar.navbar-expand {    
    padding-bottom: 0px;
    padding-top: 0px;
    display: flex;
    align-items: flex-end;
    padding-right: 15px;
}
div.nav-tabs,
div.navbar-nav.nav-tabs {
    align-items: baseline;
    border-bottom: none;
}

.nxs-tabs.nav-tabs a.nav-item {
  text-align: center;
  position: relative;
  display: inline-block;
}

.nxs-tabs.nav-tabs a.nav-item:before {
  content: '';     
      position: absolute;
      height: 40%;
      top: 30%;
      right: 0;
}

.nxs-tabs.nav-tabs .nav-link.active{
  font-weight: 700;
}

@media (min-width: 1350px) {
    .nxs-tabs.nav-tabs a.nav-item {
        line-height: 50px;
    }
}

.partial {
    position: relative;
    display: inline-block;
}
.partial:before {
    content: '';   
    position: absolute;
    height: 40%;
    top: 30%;
    right: 0;
}

.nav-tabs .nav-item{
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0px;
  border-right: 1px solid #ccc;
} 

.navbar-expand .navbar-nav .longtab.nav-link {
    padding-left: 0px;
    padding-right: 0px;
}

a.nav-link {
    color: #ABABAB;
    font-family: Roboto;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    height: 68px;
  
}

a.nav-link img {
  height: 14px;
  padding-right: 15px;
}

.nav-tabs .nav-link.active, 
a.tab.nav-link.active {
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;   
}

a.brand.nav-link,
a.brand.navbar-brand {
    text-align: left;
    padding-left: 16px;   
}

a.brand.nav-link,
a.brand.navbar-brand img {
  height: 60px;
}

.user.navbar-nav .nav-item {
    padding-left: 8px;
}

.nav-tabs a.nav-item {
  line-height: 50px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius:0;
}

.nav-tabs a.nav-item.active {
  line-height: 50px;
  border-right: 1px solid #ccc;
  border-left: 0;
  border-bottom: 1px solid #ccc;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius:0;
}