.navbar.navbar-expand {
    border-bottom: white !important;
}

.nxs-nav {
    box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.05);
}

.nxs-tabs.nav-tabs a.nav-item:before {
    content: '';
    border-right: 1px solid #CCCCCC;
    position: absolute;
    height: 40%;
    top: 30%;
    right: 0;   
}

.nxs-tabs.nav-tabs .nav-link.active {
    font-weight: 700;
}

.nav-item > a {    
    font-size: x-large  !important;    
    align-items: center !important;    
    margin-top: 30;
    height: 70  !important;
    align-items: center  !important;
    line-height: 50px !important;
    text-align: center !important;
}

.nav-item > a.active {
    color: #003049 !important;
    font-size: x-large  !important;
    height: 100%  !important;
    align-items: center  !important;
    justify-content: center  !important;
}