  .pdf {
    margin-right: 10px;
   
    padding: 5px 10px;
    width: 200px;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px #ccc;
  }
  
  /* For pdf */
  .react-pdf__Page {
    margin-top: 10px;
  }
  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
  }
  
  .react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
  }
  
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  
  /* For all pages */
  .all-page-container {
    height: 100%;   
    overflow: auto;
  }
  
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 80% !important;
}